import React from 'react';
import {Box, Typography} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';
import Vector from '../assets/icons/Vector.png'

const AgilityDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const agilityData = location.state;
    
    const artMap = ['-', 'VP', 'VM', 'DM', 'WM-Quali', 'WM'];
    
    return (
        <Box width='100%'>
            <Box mb={2}
                style={{
                    display: 'flex', alignItems: 'center', color: '#5A6872'
                }}
                onClick={() => navigate(-1)}
            >
                <Box mr={1}>
                    <img src={Vector} alt="iconLeft"/>
                </Box>

                <Typography variant="backlink">
                    Zurück zur Ergebnis-Übersicht des Teams
                </Typography>
            </Box>

            <Typography variant="subtitle1" mb={1} sx={{
                fontSize: '1rem'
            }}>{agilityData?.item?.trn_dat} | {agilityData?.item.trn_veb} | {agilityData?.item.trn_ver}</Typography>

            <Typography mb={2} variant="headertext">
                Turnier-Daten
            </Typography>

            <Box mb={2} style={{
                background: '#F0F4F8'
            }}>
                <Box p={2}>
                    <Box>
                        <Typography variant="datalabel">
                            Turnier-ID
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext" sx={{ fontWeight: 700 }}>
                            {agilityData?.item.trn_tid ? agilityData?.item.trn_tid :  `Keine Turnier-ID vorhanden`}
                        </Typography>
                    </Box>
                    
                    <Box>
                        <Typography variant="datalabel">
                            Name
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {agilityData?.item?.trn_nam ? agilityData?.item?.trn_nam :  `Kein Name vorhanden`}
                        </Typography>
                    </Box>
                    
                    <Box>
                        <Typography variant="datalabel">
                            Art
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {artMap[agilityData?.item.trn_typ]}
                        </Typography>
                    </Box>
                    
                    <Box>
                        <Typography variant="datalabel">
                            Verein
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {agilityData?.item.trn_ver}
                        </Typography>
                    </Box>
                    
                    <Box>
                        <Typography variant="datalabel">
                            Vereinsnr.
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {agilityData?.item.trn_vnr}
                        </Typography>
                    </Box>
                    
                    <Box>
                        <Typography variant="datalabel">
                            Verband
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {agilityData?.item.trn_veb}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="datalabel">
                            PL
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {agilityData?.item.trn_prl}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default AgilityDetails;