import { styled } from '@mui/system';

const CardContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F0F4F8',
    padding: '20px',
    borderRadius: '8px',
    width: '100%',
    marginBottom: 10
});

export default CardContainer;
