import { styled } from '@mui/system';

  
const CardContainerSmall = styled('div')((props) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: props.background ? props.background : '#F0F4F8',
    padding: '5px',
    borderRadius: '8px',
    width: '100%',
    height: '100%',
}));

export default CardContainerSmall;
