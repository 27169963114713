import React from 'react';
import EluCardComponent from '../components/itemCards/eluCard';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography, Grid, Box } from '@mui/material';
import Vector from '../assets/icons/Vector.png';

const AgilityList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    
    const agilityData = location.state;

    return (
        <Box>
            <Box mb={2}
                 style={{
                     display: 'flex',
                     alignItems: 'center',
                     color: '#5A6872'
                 }}
                 onClick={() => navigate(-1)}
            >
                <Box mr={1}>
                    <img src={Vector} alt="iconLeft"/>
                </Box>

                <Typography variant="backlink">
                    Zurück zum Team
                </Typography>
            </Box>

            <Grid container style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '8px'}}>
                <Grid item style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    <Typography variant="subtitle1" sx={{
                        fontSize: '1rem'
                    }}>{agilityData?.data?.hundename ? agilityData?.data?.hundename : 'Kein Hundename'}</Typography>
                    {(() => {
                        if (agilityData?.data?.name && agilityData?.data?.foreign) {
                            return (
                                <Typography variant="subtitle1" sx={{
                                    fontSize: '0.95rem'
                                }}>({agilityData?.data?.name})</Typography>
                            )
                        }
                    })()}
                    <Typography variant="subtitle1" mb={0} sx={{
                        fontWeight: 'bold',
                        fontSize: '1.1rem'
                    }}>Ergebnisse</Typography>
                </Grid>
                {
                    agilityData?.data?.agility.map((elu) =>
                        <Grid container xs={12} sm={6} style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '8px'}} item key={elu.id}>
                            <EluCardComponent item={elu}/>
                        </Grid>
                    )
                }
            </Grid>
        </Box>
    )
};

export default AgilityList;