import React from 'react';
import { Box, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate, useLocation } from 'react-router-dom';
import CardContainerSmall from "./CardContainerSmall";
import Circle  from "./Circle";

const EluCardComponent = ({ item, route = 'agility' }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const agilityData = location.state;
    
    const handleResultClick = () => {
        navigate('/elu/' + route + '/detail', { state: {data: agilityData?.data, item: item }});
    }
    
    const handleRunClick = (data) => {
        navigate('/elu/' + route + '/detail1', { state: {data: agilityData?.data, item: data }});
    }
    
    const setBackground = (data) => {
        return data?.prc_feh === '0' && data?.prc_wrt !== 'DIS' ? '#A8C2DC' : '#F0F4F8';
    };

    return (
        <>
        <Box style={{ display: 'flex', width: '100%' }} onClick={() => handleResultClick()}>
            <CardContainerSmall>
                <Circle />
                <Typography variant="cardtext">
                    {item?.trn_dat} | {item?.trn_veb} | {item?.trn_ver}
                </Typography>
                <ChevronRightIcon sx={{ marginLeft: 'auto' }} />
            </CardContainerSmall>
        </Box>

        {item?.teamdata?.map((data) =>
        <Box style={{ display: 'flex', width: '95%', marginLeft: 'auto' }} onClick={() => handleRunClick(data)} key={data.id}>
            <CardContainerSmall background={setBackground(data)}>
                <Typography variant="cardtext">
                    {data?.prc_nam.includes('+') ? 'K' : data?.prc_nam}{(() => {
                        if(data?.prc_wrt !== 'DIS') {
                            return (<> | {data?.prc_rng}/{data?.prc_sta} | {(~~data?.prc_tim / 100).toString().replace('.', ',')} s | {(~~data?.prc_spe / 100).toString().replace('.', ',')} m/s | {~~data?.prc_gpk / 100} GF | {data?.prc_feh} F | {data?.prc_ver} V{~~data?.prc_zfp === 0 ? '' : ' |' + data?.prc_zfp + ' ZF'}</>)
                        } else {
                            return (<> | {data?.prc_wrt}</>)
                        }})()}
                </Typography>
                <ChevronRightIcon sx={{ marginLeft: 'auto' }} />
            </CardContainerSmall>
        </Box>
        )}    
        </>
    );
};

export default EluCardComponent;
