import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Box, Grid, Typography} from '@mui/material';
import Vector from '../assets/icons/Vector.png';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CardContainer from "../components/itemCards/CardContainer";
import Circle from "../components/itemCards/Circle";

const Team = () => {
    const navigate = useNavigate();
    const location = useLocation();
    
    const teamData = location.state.item;
    const isCertificate = location.state.isCertificate;
    const certificateDetails = location.state.certificateDetails;

    const handleSportoverviewClick = (sport, entries) =>{
        navigate('/elu/' + sport + '/list', { state: {data: teamData, item: entries} });
    }
    
    return (
        <Box>
            <Box mb={2}
                 style={{
                     display: 'flex',
                     alignItems: 'center',
                     color: '#5A6872'
                 }}
                 onClick={() => navigate(-1)}
            >
                <Box mr={1}>
                    <img src={Vector} alt="iconLeft"/>
                </Box>

                <Typography variant="backlink">
                    Zurück zur {isCertificate ? `Leistungsurkunde` : `Übersicht`}
                </Typography>
            </Box>
            
            <Grid container spacing={2} style={{display: 'flex', flexDirection: 'column'}}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1" mb={1} sx={{
                        fontSize: '1rem'
                    }}>{teamData?.hundename ? teamData?.hundename : 'Kein Hundename'}</Typography>
                    {(() => {
                        if (teamData?.name && teamData?.foreign) {
                            return (
                                <Typography variant="subtitle1" mb={1} sx={{
                                    fontSize: '0.95rem'
                                }}>({teamData?.name})</Typography>
                            )
                        }
                    })()}
                    <Typography variant="subtitle1" mb={1} sx={{
                        fontWeight: 'bold',
                        fontSize: '1.1rem'
                    }}>Sportarten</Typography>
                </Grid>

                {teamData?.agility && teamData?.agility.length > 0 ? (
                <Grid item xs={12} sm={6} onClick={() => handleSportoverviewClick('agility', teamData?.agility, isCertificate, certificateDetails)}>
                    <CardContainer>
                        <Circle />
                        <Typography variant="cardtext">
                            Agility ({teamData?.agility.length})
                        </Typography>
                        <ChevronRightIcon sx={{ marginLeft: 'auto' }} />
                    </CardContainer>
                </Grid>
                ) : (
                    <Grid item xs={12} sm={6}>
                    <CardContainer>
                        <Circle />
                        <Typography variant="cardtext">
                            Agility (keine Ergebnisse vorhanden)
                        </Typography>
                    </CardContainer>
                </Grid>
                )}
            </Grid>
        </Box>
    )
};

export default Team;
