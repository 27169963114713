import React from 'react';
import {Box, Typography} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';
import Vector from '../assets/icons/Vector.png'

const AgilityDetails1 = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const agilityData = location.state;
    const turnierData = agilityData.data?.agility.find(t => t.id === agilityData?.item?.ergebnis_id);

    return (
        <Box width='100%'>
            <Box mb={2}
                 style={{
                     display: 'flex', alignItems: 'center', color: '#5A6872'
                 }}
                 onClick={() => navigate(-1)}
            >
                <Box mr={1}>
                    <img src={Vector} alt="iconLeft"/>
                </Box>

                <Typography variant="backlink">
                    Zurück zur Ergebnis-Übersicht des Teams
                </Typography>
            </Box>

            <Typography variant="subtitle1" mb={1} sx={{
                fontSize: '1rem'
            }}>{turnierData?.trn_dat} | {turnierData?.trn_veb} | {turnierData?.trn_ver}</Typography>

            <Typography mb={2} variant="headertext">
                Lauf Daten
            </Typography>

            <Box mb={2} style={{
                background: '#F0F4F8'
            }}>
                <Box p={2}>
                    <Box p={2} key={agilityData.item.id}>
                        <Box>
                            <Typography variant="datalabel">
                                Lauf-Nr.
                            </Typography>
                        </Box>

                        <Box mb={2}>
                            <Typography variant="datatext" sx={{ fontWeight: 700 }}>
                                {agilityData?.item?.prc_idn} ({agilityData?.item?.prc_nam})
                            </Typography>
                        </Box>

                        <Box>
                            <Typography variant="datalabel">
                                Länge (Meter)
                            </Typography>
                        </Box>

                        <Box mb={2}>
                            <Typography variant="datatext">
                                {agilityData?.item?.prc_lgp}
                            </Typography>
                        </Box>

                        <Box>
                            <Typography variant="datalabel">
                                Anz Starter
                            </Typography>
                        </Box>

                        <Box mb={2}>
                            <Typography variant="datatext">
                                {agilityData?.item?.prc_sta}
                            </Typography>
                        </Box>

                        <Box>
                            <Typography variant="datalabel">
                                LR
                            </Typography>
                        </Box>

                        <Box mb={2}>
                            <Typography variant="datatext">
                                {agilityData?.item?.prc_ric}
                            </Typography>
                        </Box>

                        <Box>
                            <Typography variant="datalabel">
                                LR-Nr.
                            </Typography>
                        </Box>

                        <Box mb={2}>
                            <Typography variant="datatext">
                                {agilityData?.item?.prc_rin}
                            </Typography>
                        </Box>

                        <Box>
                            <Typography variant="datalabel">
                                Verband LR
                            </Typography>
                        </Box>

                        <Box mb={2}>
                            <Typography variant="datatext">
                                {agilityData?.item?.prc_riv}
                            </Typography>
                        </Box>

                        <Box>
                            <Typography variant="datalabel">
                                Wertung
                            </Typography>
                        </Box>

                        <Box mb={2}>
                            <Typography variant="datatext">
                                {agilityData?.item?.prc_wrt}
                            </Typography>
                        </Box>

                        <Box>
                            <Typography variant="datalabel">
                                Rang
                            </Typography>
                        </Box>

                        <Box mb={2}>
                            <Typography variant="datatext">
                                {agilityData?.item?.prc_rng}
                            </Typography>
                        </Box>

                        <Box>
                            <Typography variant="datalabel">
                                Sollzeit
                            </Typography>
                        </Box>

                        <Box mb={2}>
                            <Typography variant="datatext">
                                {agilityData?.item?.prc_soz}
                            </Typography>
                        </Box>
                        
                        <Box>
                            <Typography variant="datalabel">
                                Laufzeit (Sekunden)
                            </Typography>
                        </Box>

                        <Box mb={2}>
                            <Typography variant="datatext">
                                {(~~agilityData?.item?.prc_tim / 100).toString().replace('.', ',')}
                            </Typography>
                        </Box>

                        <Box>
                            <Typography variant="datalabel">
                                Zeitfehler
                            </Typography>
                        </Box>

                        <Box mb={2}>
                            <Typography variant="datatext">
                                {agilityData?.item?.prc_zfp}
                            </Typography>
                        </Box>
                        
                        <Box>
                            <Typography variant="datalabel">
                                Verweigerungen
                            </Typography>
                        </Box>

                        <Box mb={2}>
                            <Typography variant="datatext">
                                {agilityData?.item?.prc_ver}
                            </Typography>
                        </Box>

                        <Box>
                            <Typography variant="datalabel">
                                Fehler
                            </Typography>
                        </Box>

                        <Box mb={2}>
                            <Typography variant="datatext">
                                {agilityData?.item?.prc_feh}
                            </Typography>
                        </Box>

                        <Box>
                            <Typography variant="datalabel">
                                Geschwindigkeit (Meter pro Sekunde)
                            </Typography>
                        </Box>

                        <Box mb={2}>
                            <Typography variant="datatext">
                                {(~~agilityData?.item?.prc_spe / 100).toString().replace('.', ',')}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default AgilityDetails1;